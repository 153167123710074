<template>
  <div class="tinymce-box">
    <editor
      v-model="myValue"
      :init="init"
      :disabled="disabled"
      :setting="{inline: false}"
      @click="onClick"
      @onfocus="textareaFocus"
      @blur="textareaBlur"
      :placeholder="placeholder"
      baseUrl="/tinymce"
      >
    </editor>
  </div>
</template>

<script>
// 文档 http://tinymce.ax-z.cn/
// 引入组件
import tinymce from 'tinymce' // tinymce默认hidden，不引入不显示
import Editor from '@tinymce/tinymce-vue'
// import  'tinymce/models/dom'
import  '/public/tinymce/skins/ui/oxide/skin.min.css'
import  '/public/tinymce/skins/ui/oxide/content.min.css'


// 引入富文本编辑器主题的js和css
import '/public/tinymce/skins/content/default/content.css'
import '/public/tinymce/themes/silver/theme.min.js'
import 'tinymce/icons/default' // 解决了icons.js 报错Unexpected token '<'
import '../../../public/tinymce/themes/silver'; //编辑器主题，不引入则报错

// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/image'// 插入上传图片插件
import 'tinymce/plugins/media'// 插入视频插件
import 'tinymce/plugins/table'// 插入表格插件
import 'tinymce/plugins/lists'// 列表插件
import 'tinymce/plugins/wordcount'// 字数统计插件
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
import 'tinymce/plugins/autolink' // 链接
import 'tinymce/plugins/autosave' //自动储存
import 'tinymce/plugins/codesample' //代码
import 'tinymce/plugins/insertdatetime' //时间
import 'tinymce/plugins/searchreplace' //查找替换
import '@/components/tinymce/plugins/tinymce-placeholder' //查找替换
 export default {
  components: {
    Editor
  },
  name: 'Tinymce',
  props: {
    // 默认的富文本内容
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
    // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
    // baseUrl: {
    //   type: String,
    //   default: window.location.origin ? window.location.origin : ''
    // },
    // 禁用
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: ' lists code preview fullscreen  codesample insertdatetime searchreplace image'
    },
    height:{
      type:Number,
      default:300
    },
    isInline:{
      type:Boolean,
      default:false
    },
    statusbar:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      baseUrl: window.location.origin ? window.location.origin : '',
      init: {
        inline: this.isInline ? true : false,
        placeholder:this.placeholder,
        language_url: require("/public/tinymce/zh_CN.js"), // 中文语言包路径
        language: "zh_CN",
        // skin_url: "../../../tinymce/skins/ui/oxide/skin.css", // 编辑器皮肤样式
        // skin_url: `/tinymce/skins/ui/oxide/skin.min.css`, // 编辑器皮肤样式
        // skin_url: 'tinymce/skins/ui/oxide-dark', // 暗色系
        // skin_url: '/tinymce/skins/ui/oxide/skin.css',
        // convert_urls: false,
        height: this.height,
        // content_css:import.meta.globEager("@/components/tinymce/skins/content/default/content.css"),
        // content_css: `${this.baseUrl}tinymce/skins/content/default/content.css`,
        // （指定需加载的插件）
        plugins: this.plugins,
        // toolbar1: 'undo redo searchreplace | styleselect |  forecolor backcolor bold italic underline strikethrough autolink',
        toolbar1: 'codesample code numlist bullist | print preview | aligncenter ｜ image',
        // toolbar2:  'alignleft aligncenter alignright alignjustify outdent indent | removeformat subscript superscript | link image media insertdatetime emoticons anchor pagebreak hr blockquote  table numlist bullist codesample | code print preview fullscreen',// （自定义工具栏）
        // fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
        // font_formats: "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        // placeholder: '请输入内容',
        // allow_html_in_named_anchor: true,//允许name锚点
        // resize: false, // 禁止改变大小
        statusbar: this.statusbar ? true :false, // 隐藏底部状态栏
        // statusbar: true, // 底部的状态栏
        // default_link_target: '_blank',
        // link_default_protocol: 'https',
        // toolbar_mode: "scrolling",//工具栏模式
        min_height: 100,
        // auto_focus : true,
        // autosave_ask_before_unload: true,
        // autosave_interval: "60s",//自动存稿
        // autosave_retention: "20m",//存稿有效期
        menubar: '', // （1级菜单）最上方的菜单
        // branding: false, // （隐藏右下角技术支持）水印“Powered by TinyMCE”
        base_url: '/tinymce',
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        // images_upload_handler: (blobInfo, success, failure) => {
        //   const img = 'data:image/jpeg;base64,' + blobInfo.base64()
        //   success(img)
        //   console.log(failure)
        // }
        images_upload_handler: (blobInfo, succFun, failFun)=> {
          var xhr;
          var file = blobInfo.blob();//转化为易于理解的file对象
          let formData = new FormData();
          formData.append('file',blobInfo.blob());
          formData.append('type',3);
          this.api.production.uploadfile(formData).then((res)=>{
            succFun(res.info.upload_path);
          })
        }
      },
      myValue: this.value,
    }
  },
  mounted () {
    // console.log(tinymce,'tinymcetinymce')
    if(!tinymce.editors.length){
      tinymce.init({});
    }


  },
  methods: {
    textareaFocus(){
      this.$emit('textareaFocus')
    },
    textareaBlur(data){
      this.$emit('textareaBlur')
    },
    // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    // 需要什么事件可以自己增加
    onClick (e) {
      this.$emit('onClick', e, tinymce)
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear () {
      this.myValue = ''
    }
  },
  watch: {
    value (newValue) {
      this.myValue = newValue;
    },
    placeholder (newValue) {
      this.placeholder = newValue
    },
    myValue (newValue) {
      this.$emit('input', newValue)
    }
  }
}

</script>
<style>
.tox-statusbar__branding{
  display: none !important;
}
</style>


