/*
 * @Author: tangzhenda 13793547659@163.com
 * @Date: 2022-05-26 17:15:32
 * @LastEditors: tangzhenda 13793547659@163.com
 * @LastEditTime: 2022-05-26 17:24:48
 * @FilePath: /test-base-management/web/src/components/tinymce/plugins/tinymce-placeholder/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
tinymce.PluginManager.add('placeholder', function(editor) {
    editor.on('init', function() {
        let label = new Label;

        onBlur();

        tinymce.DOM.bind(label.el, 'click', onFocus);
        editor.on('focus', onFocus);
        editor.on('blur', onBlur);
        editor.on('change', onBlur);
        editor.on('setContent', onBlur);
        editor.on('keydown', onKeydown);

        function onFocus() {
            if (!editor.settings.readonly === true) {
                label.hide();
            }
            editor.execCommand('mceFocus', false);
        }

        function onBlur() {
            if (editor.getContent() == '') {
                label.show();
            } else {
                label.hide();
            }
        }

        function onKeydown(){
            label.hide();
        }
    });

    let Label = function(){
        let placeholder_text = editor.getElement().getAttribute("placeholder") || editor.settings.placeholder;
        let placeholder_attrs = editor.settings.placeholder_attrs || {style: {position: 'absolute', top:'5px', left:0, color: '#888', padding: '1%', width:'98%', overflow: 'hidden', 'white-space': 'pre-wrap'} };
        let contentAreaContainer = editor.getContentAreaContainer();

        tinymce.DOM.setStyle(contentAreaContainer, 'position', 'relative');

        // Create label el
        this.el = tinymce.DOM.add( contentAreaContainer, editor.settings.placeholder_tag || "label", placeholder_attrs, placeholder_text );
    }

    Label.prototype.hide = function(){
        tinymce.DOM.setStyle( this.el, 'display', 'none' );
    }

    Label.prototype.show = function(){
        tinymce.DOM.setStyle( this.el, 'display', '' );
    }
});