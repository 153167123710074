<template>
<div>
  <tinymce class="tinymce-text" :value="obj.val" :height="height"
           @input="changeEditor" @textareaFocus="textareaFocus" :disabled="isDisabled" :isInline="isInline" :statusbar="statusbar" :placeholder="placeholder"></tinymce>
</div>
</template>

<script>
import tinymce from '@/components/tinymce/editor.vue'
export default {
  name: "richtxt1.vue",
  components:{
    tinymce
  },
  data(){
    return {
      obj:{},
      // placeholder:'请输入内容'
    }
  },
  props:{
    height:{
      type:Number,
      default:300
    },
    dataObj:{
      type:Object,
      default:{}
    },
    isDisabled:{
      type:Boolean,
      default:false
    },
    isInline:{
      type:Boolean,
      default:false
    },
    statusbar:{
      type:Boolean,
      default:false
    },
    placeholder:{
      type:String,
      default:'请输入内容'
    }
  },
  watch:{
    dataObj:{
      handler(newV,oldV){
        this.obj = newV;
      },
      immediate:true,
      // deep:true,
    },
  },
  methods:{
    changeEditor(value){
      if(value.length > 50000){
        this.$Message.warning('最多只能输入50000字');
        let maxData = value.substring(0,50000)
        this.$nextTick(()=>{
          this.$set(this.obj,'val',maxData);
        })
        this.$emit('saveData',maxData)
      }else{
        this.$emit('saveData',value)
      }
    },
    textareaFocus(){
      this.$emit('textareaFocus')
    }
  }
}
</script>

<style scoped>

</style>
